html,
body {
  /* background-color: #121212 !important; */
  background: rgb(110, 109, 91) !important;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  overflow-y: scroll;
}

p {
  margin-top: 0;
  margin-bottom: .24em;
  font-size: 1rem;
}

ul:last-child {
  margin-bottom: 0;
}

hr {
  height: 1px;
  margin: 0.35em 0;
  border: 0;
  border-top: 1px solid #eee;
}

svg.circularProgressBar {
  position: absolute;
  transform: rotate(-90deg);
  animation: offsettozero linear forwards;
  animation-iteration-count: infinite;
}
@keyframes offsettozero {
  to {
    stroke-dashoffset: 0;
  }
}

.mainSlider .react-slideshow-container .nav {
  position: absolute;
  top: 50%;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  padding: 16px;
}
.mainSlider .react-slideshow-container .nav span {
  width: 16px;
  height: 16px;
}
.mainSlider .react-slideshow-container .nav:first-of-type {
  /* margin-right: -96px; */
  left: 32px;
}
.mainSlider .react-slideshow-container .nav:last-of-type {
  /* margin-left: -96px; */
  right: 32px;
}
